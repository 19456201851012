import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import { useDispatch } from "react-redux";
import { updateCode } from "./codeReducer";

export default function CodeEditor({ lesson, onCodeSubmit }) {
  const dispatch = useDispatch();

  return (
    <AceEditor
      mode='sql'
      theme='monokai'
      placeholder='start writing your sql query here'
      onChange={(e) => dispatch(updateCode(e))}
      name='ace-editor-unique-id'
      width='100%'
      editorProps={{ $blockScrolling: true }}
      keyboardHandler='vim'
      commands={[
        {
          name: "f5KeyBinding",
          bindKey: { win: "f5", mac: "f5" },
          exec: (editor) => {
            onCodeSubmit(editor.getValue());
            //editor.insert("\n");
          }
        },
        {
          name: "ctrlEnterKeyBinding",
          bindKey: { win: "ctrl-Enter", mac: "cmd-Enter" },
          exec: (editor) => {
            onCodeSubmit(editor.getValue())
            //editor.insert("\n")
          }
        },
      ]}
      setOptions={{
        useWorker: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        fontSize: "16px",
      }}
    />
  );
}
