import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import LessonListItemPlaceholder from '../LessonListItemPlaceholder';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchTrialModules } from '../../../app/service/lessonActions';
import { TRIAL_LESSON_RETAIN_STATE } from '../../../app/service/lessonConstants';
import TrialLessonList from './TrialLessonList';

export default function TrialLessonDashboard() {
  const dispatch = useDispatch();
  const { modules, trialLessonRetainState } = useSelector((state) => state.lesson);
  const { loading } = useSelector((state) => state.async);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if (trialLessonRetainState) {
      //console.log("Trial Lesson Dashboard retainState:" + trialLessonRetainState);
      return;
    } 
    setLoadingInitial(true);
    dispatch(fetchTrialModules()).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({type: TRIAL_LESSON_RETAIN_STATE})
    }
  }, [dispatch, trialLessonRetainState]);

  return (
    <Grid>
      <Grid.Column width={14}>
        {loadingInitial && (
          <>
            <LessonListItemPlaceholder />
            <LessonListItemPlaceholder />
          </>
        )} 
        <TrialLessonList modules={modules} > </TrialLessonList>
      </Grid.Column>
      <Grid.Column width={1}>
       {/* {authenticated && <EventsFeed />}
        <EventFilters
          loading={loading}
        /> */}
      </Grid.Column>
      <Grid.Column width={2}>
        <Loader active={loading} />
      </Grid.Column>
    </Grid>
  );
}
