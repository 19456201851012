const UPDATE_CODE = 'UPDATE_CODE';

const initialState = {
    code: '',
};

export function updateCode(text) {
    return {
        type: UPDATE_CODE,
        payload: text
    }
}

export default function codeReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CODE:
      return {
        ...state,
        code: action.payload
      };
    default:
      return state;
  }
}