import {
  GET_LESSONS,
  GET_LESSON_BY_ID,
  SUBMIT_SQL_REQUEST,
  GET_MODULES,
  LESSON_RETAIN_STATE,
  ONE_LESSON_RETAIN_STATE,
  TRIAL_LESSON_RETAIN_STATE,
  RESPONSE_RETAIN_STATE,
} from "./lessonConstants";

const initialState = {
  modules: [],
  lessons: [],
  selectedLesson: null,
  sqlSubmissionResponse: null,
  lessonsRetainState: false,
  oneLessonsRetainState: false,
  responseRetainState: false,
  trialLessonRetainState: false,
};

export default function lessonReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_MODULES:
      //console.log([...state.modules, ...payload.modules]);
      return {
        ...state,
        //modules: [...state.modules, ...payload.modules],
        modules: [...payload.modules],
      };
    case GET_LESSONS:
      return {
        ...state,
        //lessons: [...state.lessons, ...payload.lessons],
        lessons: [...payload.lessons],
      };
    case GET_LESSON_BY_ID:
      return {
        ...state,
        selectedLesson: payload.selectedLesson,
      };
    case SUBMIT_SQL_REQUEST:
      return {
        ...state,
        sqlSubmissionResponse: payload.sqlSubmissionResponse,
      };
    case ONE_LESSON_RETAIN_STATE:
      return {
        ...state,
        oneLessonsRetainState: true,
      };
    case LESSON_RETAIN_STATE:
      return {
        ...state,
        lessonsRetainState: true,
        trialLessonRetainState: false,
      };
    case RESPONSE_RETAIN_STATE:
      return {
        ...state,
        responseRetainState: true,
      };
    case TRIAL_LESSON_RETAIN_STATE:
      return {
        ...state,
        trialLessonRetainState: true,
        lessonsRetainState: false,
      };
    default:
      return state;
  }
}
