import React from "react";
import { Grid, Segment, Message } from "semantic-ui-react";
import SqlTable from "./SqlTable";

import ModalWrapper from "../../app/common/modals/ModalWrapper";

export default function QuestionModal({ lesson }) {
  return (
    <ModalWrapper size='large' header=''>
      <>
        <Segment textAlign='center'>
          <Message>{lesson.questionText}</Message>
          <Grid verticalAlign='middle' columns={2} textAlign='center'>
            {lesson.dataTables &&
              lesson.dataTables.length > 0 &&
              lesson.dataTables.map((table, i) => (
                <Grid.Column key={i}>
                  <SqlTable tableData={table}></SqlTable>
                </Grid.Column>
              ))}
          </Grid>
        </Segment>
      </>
    </ModalWrapper>
  );
}
