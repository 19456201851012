import React from 'react';
import { Menu, Container } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import SignedOutMenu from './SignedOutMenu';
import SignedInMenu from './SignedInMenu';
import { useSelector } from 'react-redux';

export default function NavBar({ setFormOpen }) {
  const {authenticated} = useSelector(state => state.auth);

  return (
    <Menu inverted fixed='top' stackable>
      <Container>
        <Menu.Item as={NavLink} exact to='/' header>
          <img src='/assets/bloom_logo.png' alt='logo' style={{ marginRight: 15 }} />
          Bloom SQL
        </Menu.Item>
        <Menu.Item as={NavLink} to='/triallessons' name='Free Lessons' />
        {/*<Menu.Item as={NavLink} to='/checkout' name='Checkout' />*/}
        <Menu.Item as={NavLink} to='/lessons' name='Lessons' />
        {/*<Menu.Item as={NavLink} to='/sandbox' name='Sandbox' />*}
        {/*{authenticated && (
          <Menu.Item as={NavLink} to='/createEvent'>
            <Button positive inverted content='Create Event' />
          </Menu.Item>
        )} */}
        {authenticated ? (
          <SignedInMenu />
        ) : (
          <SignedOutMenu />
        )}
      </Container>
    </Menu>
  );
}
