export const GET_LESSONS = 'GET_LESSONS';
export const GET_MODULES = 'GET_MODULES';
export const GET_LESSONS_BY_MODULE = 'GET_LESSONS_BY_MODULE';
export const GET_LESSON_BY_ID = 'GET_LESSON_BY_ID';
export const LISTEN_TO_SELECTED_LESSON = 'LISTEN_TO_SELECTED_LESSON';
export const SUBMIT_SQL_REQUEST = 'SUBMIT_SQL_REQUEST';
export const LESSON_RETAIN_STATE = 'LESSON_RETAIN_STATE';
export const ONE_LESSON_RETAIN_STATE = 'ONE_LESSON_RETAIN_STATE';
export const RESPONSE_RETAIN_STATE = 'RESPONSE_RETAIN_STATE';
export const TRIAL_LESSON_RETAIN_STATE = 'TRIAL_LESSON_RETAIN_STATE';
export const AGREE_TO_RECEIVE_MSG = '';
export const MUST_AGREE_TO_EMAIL_MSG = 'Please agree to the Terms of Service and Privacy Policy above.';
export const EMAIL_FORM_HEADER = 'provide your email to receive link';