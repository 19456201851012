import React from "react";
import ResponseArea from "./ResponseArea";
import Question from "./Question";
import LessonListItemPlaceholder from "./LessonListItemPlaceholder";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchLessonById } from "../../app/service/lessonActions";
import { ONE_LESSON_RETAIN_STATE } from "../../app/service/lessonConstants";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { openModal } from "../../app/common/modals/modalReducer";
import RequestToBuyPage from "./trial/RequestToBuyPage";

export default function Lesson({ match }) {
  const dispatch = useDispatch();
  const { selectedLesson } = useSelector((state) => state.lesson);
  const { jwtToken, authenticated } = useSelector((state) => state.auth);
  const [loadingInitial, setLoadingInitial] = useState(true); // might have to start with false
  const history = useHistory();

  useEffect(() => {
    console.log(authenticated);
    if (!authenticated) return;
    setLoadingInitial(true);
    dispatch(fetchLessonById(jwtToken, match.params.id)).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: ONE_LESSON_RETAIN_STATE });
    };
  }, [authenticated, dispatch, jwtToken, match.params.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onNext() {
    // if lesson is not found selectedLesson will be saved as null.
    // if it's null we return. ideally we should disable the onNext button.
    if (selectedLesson === null) {
      return;
    }
    let nextId = `${selectedLesson.id + 1}`;
    history.push(`/lessons/${nextId}`);
  }

  function onHint() {
    //console.log("on hint called")
    dispatch(
      openModal({
        modalType: "HintModal",
        modalProps: { selectedLesson },
      })
    );
  }

  return (
    <>
    {
      !authenticated && (
        <RequestToBuyPage message="Please buy subscription to view this page" message2={""}></RequestToBuyPage>
      )
    }
      {loadingInitial && (
        <>
          <LessonListItemPlaceholder />
          <LessonListItemPlaceholder />
        </>
      )}
      {!loadingInitial && authenticated && (
        <>
          <Question lesson={selectedLesson}></Question>
          <ResponseArea
            lesson={selectedLesson}
            onHint={onHint}
            onNext={onNext}
          ></ResponseArea>
        </>
      )}
    </>
  );
}
