import React from "react";
import ResponseArea from "../ResponseArea";
import Question from "../Question";
import LessonListItemPlaceholder from "../LessonListItemPlaceholder";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchLessonById } from "../../../app/service/lessonActions";
import { LESSON_RETAIN_STATE } from "../../../app/service/lessonConstants";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import RequestToBuyPage from "./RequestToBuyPage";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function TrialLesson({ match }) {
  const dispatch = useDispatch();
  const { selectedLesson } = useSelector((state) => state.lesson);
  const { jwtToken, authenticated } = useSelector((state) => state.auth);
  const [loadingInitial, setLoadingInitial] = useState(true); // might have to start with false
  const [trialFinished, setTrialFinished] = useState(false);
  const history = useHistory();

  const message = 'More lessons coming soon.'
  const message2= 'Please register to stay tuned.'

  useEffect(() => {
    setLoadingInitial(true);
    dispatch(fetchLessonById(jwtToken, match.params.id)).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({ type: LESSON_RETAIN_STATE });
    };
  }, [authenticated, dispatch, jwtToken, match.params.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onNext() {
    // if lesson is not found selectedLesson will be saved as null.
    // if it's null we return. ideally we should disable the onNext button.
    let nextId = `${selectedLesson.id + 1}`;
    if (nextId > 9005) {
      setTrialFinished(true);
      return;
    }
    history.push(`/triallessons/${nextId}`);
  }

  function onHint() {
    //console.log("on hint called");
    //console.log({ selectedLesson });
    dispatch(
      openModal({ 
        modalType: "HintModal", 
        modalProps: { selectedLesson },
       })
    );
  }

  return (
    <>
      {trialFinished && <RequestToBuyPage message={message} message2={message2}></RequestToBuyPage>}
      {loadingInitial && (
        <>
          <LessonListItemPlaceholder />
          <LessonListItemPlaceholder />
        </>
      )}
      {!loadingInitial && (
        <>
          <Question lesson={selectedLesson}></Question>
          <ResponseArea
            lesson={selectedLesson}
            onHint={onHint}
            onNext={onNext}
          ></ResponseArea>
        </>
      )}
    </>
  );
}
 