import React from "react";
import { Message } from "semantic-ui-react";

export default function QuestionSummary({ lessonSummary }) {
  return (
    <>
        <Message.Content size='small'>{lessonSummary.header}</Message.Content>
    </>
  );
}
