import React from "react";
import { Message } from "semantic-ui-react";

import ModalWrapper from "../../app/common/modals/ModalWrapper";

export default function HelpModal() {
  return (
    <ModalWrapper size='large' header=''>
      <Message>
        <Message.Header>Help</Message.Header>
        <Message.List>
          <Message.Item>Press Ctrl + Enter on Windows or Cmd + Enter on Mac to execute query.</Message.Item>
          <Message.Item>Check the result of your query against the expected result.</Message.Item>
          <Message.Item>There are many ways to write a SQL query, best way to validate is to compare results</Message.Item>
        </Message.List>
      </Message>
    </ModalWrapper>
  );
}
