import React from "react";
import { Segment, Grid } from "semantic-ui-react";
import CodeEditor from "./CodeEditor";
import ToolBar from "./ToolBar";
import Answer from "./Answer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { submitSqlRequest } from "../../app/service/lessonActions";

export default function ResponseArea({ lesson, onHint, onNext }) {
  const dispatch = useDispatch();
  const { sqlSubmissionResponse } = useSelector(
    (state) => state.lesson
  );
  const { jwtToken } = useSelector((state) => state.auth);
  const { code } = useSelector((state) => state.code);
  const [responseReceived, setResponseReceived] = useState(false);

  function onCodeSubmit(val) {
    //console.log(val);
    const sqlSubmissionResponse = {
      lessonId: lesson.id,
      userQuery: val.trim(),
    };
    //console.log("Response Area: execute this query: ", sqlSubmissionResponse);
    dispatch(submitSqlRequest(jwtToken, sqlSubmissionResponse)).then(() => {
      setResponseReceived(true);
    });
  }

  function onExecute() {
    const sqlSubmissionResponse = {
      lessonId: lesson.id,
      userQuery: code.trim(),
    };
    dispatch(submitSqlRequest(jwtToken, sqlSubmissionResponse)).then(() => {
      setResponseReceived(true);
    });
  }

  return (
    <Segment>
      <Grid>
        <Grid.Column width={10} mobile={16} tablet={8} computer={10}>
          <ToolBar onExecute={onExecute} onHint={onHint} onNext={onNext}></ToolBar>
          <Segment>
            <CodeEditor
              lesson={lesson}
              onCodeSubmit={onCodeSubmit}
            ></CodeEditor>
          </Segment>
        </Grid.Column>
        <Grid.Column width={6} mobile={16} tablet={8} computer={6}>
          {responseReceived && <Answer answer={sqlSubmissionResponse}></Answer>}
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
