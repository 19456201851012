import React from "react";
import { Menu } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { FORGOT_PASSWORD_FORM_HDR } from "../../app/service/lessonConstants";

export default function ForgotPassword() {
  const dispatch = useDispatch();

  function handleForgotPassword() {
    dispatch(closeModal());
    dispatch(openModal({ modalType: "ForgotPasswordForm" }));
  }

  function handleRegisterForm() {
    dispatch(closeModal());
    dispatch(openModal({ modalType: "RegisterForm" }));
  }

  return (
    <>
      <Menu size='big' text borderless widths={2}>
        <Menu.Item color={'blue'} link onClick={() => handleForgotPassword()}>
          Forgot Password?
        </Menu.Item>
        <Menu.Item color={'blue'} link onClick={() => handleRegisterForm()}>
          Register?
        </Menu.Item>
      </Menu>
      {/*<Menu size='big' text borderless widths={1}>
        <Menu.Item color={'blue'} link onClick={() => handleForgotPassword()}>
          Forgot Password?
        </Menu.Item>
  </Menu>*/}
      </>
  );
}
