import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button, Label} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { sendPasswordResetLink } from "../../app/firestore/firebaseService";
import { EMAIL_FORM_HEADER } from "../../app/service/lessonConstants";

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();

  const refreshPage = () => {
    window.onload = function() {
        if(!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
        }
    }
  };

  return (
    <ModalWrapper size='mini' header={EMAIL_FORM_HEADER}>
      <Formik
        initialValues={{ email: ""}}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await sendPasswordResetLink(values);
            setSubmitting(false);
            dispatch(closeModal());
            refreshPage();
          } catch (error) {
            //console.log(error);
            setErrors({ auth: "Problem with username" });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='email' placeholder='Email Address' />
            {errors.auth && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              fluid
              size='large'
              color='teal'
              content='Login'
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
