import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import LessonListItemPlaceholder from './LessonListItemPlaceholder';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchModules } from '../../app/service/lessonActions';
import { LESSON_RETAIN_STATE } from '../../app/service/lessonConstants';
import LessonList from './LessonList';

export default function LessonDashboard() {
  const dispatch = useDispatch();
  const { modules, lessonsRetainState } = useSelector((state) => state.lesson);
  const { loading } = useSelector((state) => state.async);
  const { jwtToken, authenticated } = useSelector((state) => state.auth);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useEffect(() => {
    if (lessonsRetainState) {
      //console.log("Lesson Dashboard retainState:" + lessonsRetainState);
      return;
    } 
    setLoadingInitial(true);
    dispatch(fetchModules(jwtToken)).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({type: LESSON_RETAIN_STATE})
    }
  }, [authenticated, dispatch, jwtToken, lessonsRetainState]);

  return (
    <Grid>
      <Grid.Column width={14}>
        {loadingInitial && (
          <>
            <LessonListItemPlaceholder />
            <LessonListItemPlaceholder />
          </>
        )}
        { authenticated && 
        <LessonList modules={modules} > </LessonList>}
        { !authenticated &&
          <h1>Please login to view lessons</h1>
        }
      </Grid.Column>
      <Grid.Column width={1}>
       {/* {authenticated && <EventsFeed />}
        <EventFilters
          loading={loading}
        /> */}
      </Grid.Column>
      <Grid.Column width={2}>
        <Loader active={loading} />
      </Grid.Column>
    </Grid>
  );
}
