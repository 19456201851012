import React from "react";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { socialLogin } from "../../app/firestore/firebaseService";
import { MUST_AGREE_TO_EMAIL_MSG } from "../../app/service/lessonConstants";

export default function SocialLogin({message, checkAccept, accepted, updateNotAcceptedError}) {
  const dispatch = useDispatch();

  const errorMessage = MUST_AGREE_TO_EMAIL_MSG;

  const refreshPage = () => {
    window.onload = function () {
      if (!window.location.hash) {
        window.location = window.location + "#loaded";
        window.location.reload();
      }
    };
  };

  function handleSocialLogin(provider) {
    if (checkAccept === true) {
      if (accepted !== true) {
        console.log("accepted in if condition" + accepted);
        updateNotAcceptedError(errorMessage)
        return;
      }
    }
    dispatch(closeModal());
    socialLogin(provider);
    refreshPage();
  }

  return (
    <>
      {/*<Button
        onClick={() => handleSocialLogin("twitter")}
        icon='twitter'
        fluid
        color='twitter'
        style={{ marginBottom: 10 }}
        content='Login with Twitter'
      />*/}
      {/*<Button
        onClick={() => handleSocialLogin("facebook")}
        icon='facebook'
        fluid
        color='facebook'
        style={{ marginBottom: 10 }}
        content='Login with Facebook'
      />*/}
      <Button
        onClick={() => handleSocialLogin("google")}
        icon='google'
        fluid
        color='google plus'
        content={`${message} with Google`}
      />
    </>
  );
}
