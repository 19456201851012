import React, { useState } from 'react';
import { Grid, Header, Button, Tab } from 'semantic-ui-react';

export default function AboutTab({ profile, isCurrentUser }) {
  const [editMode, setEditMode] = useState(false);
  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <Header
            floated='left'
            icon='user'
            content={`About ${profile.displayName}`}
          />
          {isCurrentUser &&
          <Button
            onClick={() => setEditMode(!editMode)}
            floated='right'
            basic
            content={editMode ? 'Cancel' : 'Edit'}
          />}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
}
