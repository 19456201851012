import { setUserProfileData } from './firestoreService';
import { toast } from 'react-toastify';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
  FacebookAuthProvider,
  GoogleAuthProvider,
  updatePassword,
  TwitterAuthProvider,
  signInWithRedirect,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { getDatabase, ref as fbRef, push, query, orderByKey, limitToLast } from 'firebase/database';
import { getStorage, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { app } from '../config/firebase';

const auth = getAuth(app);
const db = getDatabase(app);

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map(e => Object.assign({}, e[1], { id: e[0] }))
  }
}

export function deleteUser() {
  const user = auth.currentUser;
  user.delete().then(() => {
    signOut(auth);
  }).catch((error) => {
    throw error;
  });
}

export function sendPasswordResetLink(creds) {
  const actionCodeSettings = {
    // do not forget the https portion.
    'url': "https://bloomsql.com",
  }
  return sendPasswordResetEmail(auth, creds.email, actionCodeSettings);
}

export async function signInWithEmail(creds) {
  const result = await signInWithEmailAndPassword(auth, creds.email, creds.password);
  // TODO when we add email verification.
  if (result.user.emailVerified !== true) {
    // TODO can we show some message on the login screen for a few seconds?
    // TODO we might want to sign out later
    //signOutFirebase();
    //throw Error("Please verify your email to login");
  }
}

export function signOutFirebase() {
  return signOut(auth);
}

export async function registerInFirebase(creds) {
  try {
    const result = await createUserWithEmailAndPassword(auth, creds.email, creds.password);
    const actionCodeSettings = {
      // do not forget the https portion.
      'url': "https://bloomsql.com/triallessons",
    }
    await sendEmailVerification(result.user, actionCodeSettings);
    await updateProfile(result.user, {
      displayName: creds.email
    })
    await setUserProfileData(result.user);
    //signOutFirebase();
  } catch (error) {
    throw error;
  }
}

export async function socialLogin(selectedProvider) {
  let provider;
  if (selectedProvider ==='twitter') {
    provider = new TwitterAuthProvider();
  }
  if (selectedProvider === 'facebook') {
      provider = new FacebookAuthProvider();
  }
  if (selectedProvider === 'google') {
      provider = new GoogleAuthProvider();
  }
  try {
      const result = await signInWithRedirect(auth, provider);
      if (result._tokenResponse.isNewUser) {
          await setUserProfileData(result.user);
      }
  } catch (error) {
      toast.error(error.message);
  }
}

export function updateUserPassword(creds) {
  const user = auth.currentUser;
  return updatePassword(user, creds.newPassword1);
}

export function uploadToFirebaseStorage(file, filename) {
  const user = auth.currentUser;
  const storage = getStorage(app);
  const storageRef = ref(storage, `${user.uid}/user_images/${filename}`);
  return uploadBytesResumable(storageRef, file);
}

export function deleteFromFirebaseStorage(filename) {
  const userUid = auth.currentUser.uid;
  const storage = getStorage(app);
  const storageRef = ref(storage, `${userUid}/user_images/${filename}`);
  return deleteObject(storageRef)
}

export function addEventChatComment(eventId, values) {
  const user = auth.currentUser;
  const newComment = {
      displayName: user.displayName,
      photoURL: user.photoURL,
      uid: user.uid,
      text: values.comment,
      date: Date.now(),
      parentId: values.parentId
  }
  return push(fbRef(db, `chat/${eventId}`), newComment);
}

export function getEventChatRef(eventId) {
  return query(fbRef(db, `chat/${eventId}`), orderByKey())
}

export function getUserFeedRef() {
  const user = auth.currentUser;
  if (!user) return;
  return query(fbRef(db, `posts/${user.uid}`), orderByKey(), limitToLast(5))
}