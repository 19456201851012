import React from "react";
import QuestionSummary from "./QuestionSummary";
import { Segment, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function LessonSummary({ lessonSummary }) {
  //console.log(lessonSummary);
  return (
    <Segment as={Link} to={`/lessons/${lessonSummary.id}`} style={{display:'block'}} >
      <QuestionSummary lessonSummary={lessonSummary}></QuestionSummary>
        <Button
          as={Link} to={`/lessons/${lessonSummary.id}`}
          icon='right arrow'
          size='mini'
        />
    </Segment>
  );
}
