import React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import TrialLessonSummary from "./TrialLessonSummary";

export default function TrialLessonList({ modules }) {
  //console.log(modules);
  return (
    <>
      {modules.map((module) => (
        <Segment>
        <Header>{module.module}</Header>
        <Grid celled>
          {module.lessonSummaries.map((lessonSummary) => (
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <TrialLessonSummary lessonSummary={lessonSummary} key={lessonSummary.id} />
            </Grid.Column>
          ))}
        </Grid>
        </Segment>
      ))}
    </>
  );
}
