import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncReducer";
import {
  getLessons,
  getLessonsByModule,
  getLessonById,
  submitSqlSubmissionRequest,
  getModules,
  getTrialModules,
} from "./lessonService";
import {
  GET_LESSONS,
  GET_LESSON_BY_ID,
  SUBMIT_SQL_REQUEST,
  GET_MODULES,
} from "./lessonConstants";

export function fetchTrialModules(jwtToken) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const modulesRaw = await getTrialModules(jwtToken);
      const modules = await modulesRaw.json();
      //console.log(modules[0].lessonSummaries[0]);
      dispatch({ type: GET_MODULES, payload: { modules } });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function fetchModules(jwtToken) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const modulesRaw = await getModules(jwtToken);
      const modules = await modulesRaw.json();
      //console.log(modules[0].lessonSummaries[0]);
      dispatch({ type: GET_MODULES, payload: { modules } });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function fetchLessons(jwtToken) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const lessonsRaw = await getLessons(jwtToken);
      const lessons = await lessonsRaw.json();
      dispatch({ type: GET_LESSONS, payload: { lessons } });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function fetchLessonsByModule(jwtToken, module) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const lessonsRaw = await getLessonsByModule(jwtToken, module);
      const lessons = await lessonsRaw.json();
      dispatch({ type: GET_LESSONS, payload: { lessons } });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function fetchLessonById(jwtToken, id) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const selectedLessonRaw = await getLessonById(jwtToken, id);
      let selectedLesson = null;
      if (selectedLessonRaw.status === 200) {
        selectedLesson = await selectedLessonRaw.json();
      }
      dispatch({ type: GET_LESSON_BY_ID, payload: { selectedLesson } });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

export function submitSqlRequest(jwtToken, sqlSubmissionRequest) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const sqlSubmissionResponseRaw = await submitSqlSubmissionRequest(
        jwtToken,
        sqlSubmissionRequest
      );
      const sqlSubmissionResponse = await sqlSubmissionResponseRaw.json();
      dispatch({
        type: SUBMIT_SQL_REQUEST,
        payload: { sqlSubmissionResponse },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}
