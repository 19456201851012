import React from "react";
import {
  Grid,
  Segment,
  Rating,
  Message,
} from "semantic-ui-react";
import SqlTable from "./SqlTable";

export default function Answer({ answer }) {
  //console.log(answer);
  var stars = [];
  if (answer !== null && answer.resultDetails !== null) {
    for (var i = 0; i < answer.resultDetails.points; i++) {
      stars.push(i);
    }
  }

  return (
    <Segment textAlign='center' size='huge'>
      <Rating
        size='massive'
        disabled
        icon='star'
        maxRating={5}
        rating={stars.length}
      />
      <>
        <Message size='mini'>{answer.remarks}</Message>
        <Grid columns={1} textAlign='center'>
          {answer.tables &&
            answer.tables.length > 0 &&
            answer.tables.map((table, i) => (
              <Grid.Column key={i}>
                <SqlTable tableData={table}></SqlTable>
              </Grid.Column>
            ))}
        </Grid>
      </>

      {answer.resultDetails && answer.resultDetails.expectedTables && (
        <>
          <Message size='mini'>Expected Result</Message>
          <Grid columns={1} textAlign='center'>
            {answer.resultDetails.expectedTables &&
              answer.resultDetails.expectedTables.length > 0 &&
              answer.resultDetails.expectedTables.map((table, i) => (
                <Grid.Column key={i}>
                  <SqlTable tableData={table}></SqlTable>
                </Grid.Column>
              ))}
          </Grid>
        </>
      )}
    </Segment>
  );
}
