import React from "react";
import QuestionSummary from "../QuestionSummary";
import { Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function TrialLessonSummary({ lessonSummary }) {
  //console.log(lessonSummary);
  return (
    <Segment compact raised color='blue' clearing as={Link} to={`/triallessons/${lessonSummary.id}`} style={{display:'block'}} >
      <QuestionSummary lessonSummary={lessonSummary}></QuestionSummary>
        {/*<Button
          as={Link} to={`/triallessons/${lessonSummary.id}`}
          icon='right arrow'
          size='medium'
          color='blue'
        />*/}
    </Segment>
  );
}
