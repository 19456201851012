import React from "react";
import LessonSummary from "./LessonSummary";
import { Grid, Header, Segment } from "semantic-ui-react";

export default function LessonList({ modules }) {
  //console.log(modules);
  return (
    <>
      {modules.map((module) => (
        <Segment>
        <Header>{module.module}</Header>
        <Grid columns={6} celled>
          {module.lessonSummaries.map((lessonSummary) => (
            <Grid.Column>
              <LessonSummary lessonSummary={lessonSummary} key={lessonSummary.id} />
            </Grid.Column>
          ))}
        </Grid>
        </Segment>
      ))}
    </>
  );
}
