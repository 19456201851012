import React from "react";
import { Table, Header, Segment } from "semantic-ui-react";

export default function SqlTable({ tableData }) {

  return (
    <Segment size='mini'>
      <Header as='h5'>{tableData.tableName}</Header>
      <Table celled compact size='small' fixed unstackable>
        <Table.Header>
          <Table.Row>
            {tableData.headers.map((header, i) => (
              <Table.HeaderCell key={i}>{header}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableData.dataRows.map((row, i) => (
            <Table.Row key={i}>
              {row.map((cell, j) => (
                <Table.Cell key={j}>{cell}</Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}
