import React from "react";
import {
  Segment,
  Container,
  Header,
  Image,
  Button,
  Icon,
} from "semantic-ui-react";

export default function HomePage({ history }) {
  return (
    <Segment inverted textAlign='center' vertical className='masthead'>
      <Container>
        <Header as='h2' inverted>
          <Image
            size='massive'
            src='/assets/bloom_logo.png'
            style={{ marginBottom: 20 }}
          />
          BloomSQL
        </Header>
        <Header id='mastehead-header-unique-id' as='h1' inverted>
          <br />
          Learning SQL &nbsp;
          <span style={{ color: "red", textDecoration: "line-through" }}>
            <span style={{ color: "white" }}>is SO HARD</span>
          </span>
          <br />
          has never been easier
        </Header>
        <Button
          onClick={() => history.push("/triallessons")}
          size='huge'
          
          color='green'
        >
          Try An Exercise
          <Icon name='right arrow' inverted />
        </Button>
      </Container>
    </Segment>
  );
}
