import React from "react";
import { Grid, Segment, Message } from "semantic-ui-react";
import SqlTable from "./SqlTable";

export default function Question({ lesson }) {
  //console.log("lesson: " + lesson);
  return (
    <Segment textAlign='center'>
      {lesson && (
        <>
          <Message>{lesson.questionText}</Message>
          <Segment size='mini' textAlign='center' vertical>
            <Grid verticalAlign='middle' columns={2} textAlign='center'>
              {lesson.dataTables &&
                lesson.dataTables.length > 0 &&
                lesson.dataTables.map((table, i) => (
                  <Grid.Column key={i} mobile={16} tablet={8} computer={8}>
                    <SqlTable tableData={table}></SqlTable>
                  </Grid.Column>
                ))}
            </Grid>
          </Segment>
        </>
      )}
      {!lesson && <Message>Lesson not found. You might have reached the end of this course</Message>}
    </Segment>
  );
}
