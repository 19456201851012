const BASE_URL =  "https://api.bloomsql.com"; //"http://localhost:8080";
const LESSONS = "/lessons";
const MODULES = "/modules";
const TRIAL_MODULES = "/trial-modules";
const LESSONS_BY_MODULE = "/lessons/modules";
const RESPONSES = "/responses";

export function getTrialModules() {
  return fetch(BASE_URL + TRIAL_MODULES, {
    method: "get",
  });
}

export function getModules(jwtToken) {
  //console.log("JWT:  " + jwtToken);
  return fetch(BASE_URL + MODULES, {
    method: "get",
    headers: new Headers({
      Authorization: "Bearer " + jwtToken,
    }),
  });
}

/*
response

[
    {
        id: ,
        module: ,
        header: ,
        question: ,
        tables: ,
    }
]

*/
export function getLessons(jwtToken) {
  //console.log("JWT:  " + jwtToken);
  return fetch(BASE_URL + LESSONS, {
    method: "get",
    headers: new Headers({
      Authorization: "Bearer " + jwtToken,
    }),
  });
}

/*
response

[
    {
        id: ,
        module: ,
        header: ,
        question: ,
        tables: ,
    }
]

*/

export function getLessonsByModule(jwtToken, module) {
  //console.log("JWT:  " + jwtToken);
  return fetch(BASE_URL + LESSONS_BY_MODULE + "/" + module, {
    method: "get",
    headers: new Headers({
      Authorization: "Bearer " + jwtToken,
    }),
  });
}

export function getLessonById(jwtToken, id) {
  return fetch(BASE_URL + LESSONS + "/" + id, {
    method: "get",
    headers: new Headers({
      Authorization: "Bearer " + jwtToken,
    }),
  });
}

/*
request

{
    id: ,
    answer: {
        query: 
    }
}

response 
{
    id: ,
    nextLessonId: ,
    feedback: {
        points: ,
        remarks: ,
        tables: 
    }

}
*/
export function submitSqlSubmissionRequest(jwtToken, sqlSubmissionRequest) {
    return fetch(BASE_URL + RESPONSES + '/' + sqlSubmissionRequest.lessonId, {
        method: "post",
        headers: new Headers({
          Authorization: "Bearer " + jwtToken,
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(sqlSubmissionRequest)
      });
}