import React from "react";
import { Segment, Grid, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";

export default function ToolBar({onExecute, onHint, onNext}) {
  const dispatch = useDispatch();
  return (
    <Segment>
      <Grid columns={4}>
        {/*<Grid.Column>
          <Button
            icon='retweet'
            label='tweet'
            labelPosition='left'
            floated='left'
          ></Button>
        </Grid.Column>*/}
        <Grid.Column only='computer' mobile={8} tablet={8} computer={4}>
          <Button
            icon='keyboard'
            label='keys'
            labelPosition='left'
            floated='left'
            onClick={() => dispatch(openModal({modalType: 'HelpModal'}))}
          ></Button>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={4}>
          <Button
            icon='help'
            label='hint'
            labelPosition='left'
            floated='left'
            onClick={onHint}
          ></Button>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={4}>
          <Button
            icon='arrow alternate circle right'
            label='next'
            labelPosition='left'
            floated='left'
            onClick={onNext}
          ></Button>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={4}>
          <Button
            icon='play'
            label='run'
            labelPosition='left'
            floated='left'
            onClick={onExecute}
          ></Button>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
