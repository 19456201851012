import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB0MydoE_ufNwLzR_bXsBikjlxHtes9c5c",
  authDomain: "bloomsql.com",
  projectId: "bloomsql-5e0df",
  storageBucket: "bloomsql-5e0df.appspot.com",
  messagingSenderId: "844671165580",
  appId: "1:844671165580:web:31d8e570921bfe6d8f87a9",
};

export const app = initializeApp(firebaseConfig);
