import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import {
  Button,
  Label,
  Divider,
  Radio,
  Checkbox,
  Segment,
} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { registerInFirebase } from "../../app/firestore/firebaseService";
import SocialLogin from "./SocialLogin";
import { useState } from "react";
import {
  AGREE_TO_RECEIVE_MSG,
  MUST_AGREE_TO_EMAIL_MSG,
} from "../../app/service/lessonConstants";

export default function RegisterForm() {
  const dispatch = useDispatch();
  const [agreeRadio, setAgreeRadio] = useState(false);
  const [notAcceptedError, setNotAcceptedError] = useState(null);
  const [checkBoxDirty, setCheckBoxDirty] = useState(false);

  function updateNotAcceptedError(error) {
    setNotAcceptedError(error);
  }

  function updateRadio(value) {
    setAgreeRadio(value);
    setCheckBoxDirty(true);
  }

  return (
    <ModalWrapper size='tiny' header='    '>
      <Segment>
        <Checkbox
          id='agree_checkbox_id'
          onChange={() => updateRadio(!agreeRadio)}
          checked={agreeRadio}
          label=' '
        />
        <label
          for='agree_checkbox_id'
          class='text-gray-500 dark:text-gray-400 text-sm font-medium'
        >
          I agree to the bloomsql.com{" "}
          <a
            href='https://bloomsql.com/terms'
            target='_blank'
            class='hover:text-gray-700 dark:hover:text-gray-200 underline'
          >
            Terms of Service
          </a>{" "}
          and I'm aware that my personal data is processed in accordance with our{" "}
          <a
            href='https://bloomsql.com/privacy'
            target='_blank'
            class='hover:text-gray-700 dark:hover:text-gray-200 underline'
          >
            Privacy Policy
          </a>
        </label>
        {notAcceptedError && agreeRadio !== true && (
          <Label
            icon='warning sign'
            basic
            color='red'
            style={{ marginBottom: 10 }}
            content={notAcceptedError}
          />
        )}
      </Segment>
      <SocialLogin
        message='Sign Up'
        checkAccept={true}
        accepted={agreeRadio}
        updateNotAcceptedError={updateNotAcceptedError}
      />
      <Divider horizontal style={{ "text-transform": "lowercase" }}>
        Or sign up with your email
      </Divider>
      <Formik
        initialValues={{ displayName: "", email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            setCheckBoxDirty(false);
            if (agreeRadio !== true) {
              throw new Error(MUST_AGREE_TO_EMAIL_MSG);
            }
            await registerInFirebase(values);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({ auth: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='email' placeholder='Email Address' />
            <MyTextInput
              name='password'
              placeholder='Password'
              type='password'
            />
            {errors.auth && checkBoxDirty !== true && (
              <Label
                icon='warning sign'
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              //disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              fluid
              size='large'
              color='teal'
              content='Register'
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
