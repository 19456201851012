import React from "react";
import { Segment, Message, Menu, Button } from "semantic-ui-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function RequestToBuyPage({ message, message2 }) {
  const dispatch = useDispatch();
  //console.log(modules);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Segment textAlign='center'>
      <Message size='massive' positive>
        <Message.Content>{message}</Message.Content>
        <Message.Content>{message2}</Message.Content>
      </Message>
      <Menu.Item position='right' size='massive'>
        <Button
          onClick={() => dispatch(openModal({ modalType: "RegisterForm" }))}
          basic
          color='blue'
          content='Register'
          style={{ marginLeft: "0.5em" }}
        />
      </Menu.Item>
    </Segment>
  );
}
