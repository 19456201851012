import React from 'react';
import EventDashboard from '../../features/events/eventDashboard/EventDashboard';
import NavBar from '../../features/nav/NavBar';
import { Container } from 'semantic-ui-react';
import { Route, useLocation } from 'react-router-dom';
import HomePage from '../../features/home/HomePage';
import EventDetailedPage from '../../features/events/eventDetailed/EventDetailedPage';
import EventForm from '../../features/events/eventForm/EventForm';
import Sandbox from '../../features/sandbox/Sandbox';
import ModalManager from '../common/modals/ModalManager';
import { ToastContainer } from 'react-toastify';
import ErrorComponent from '../common/errors/ErrorComponent';
import AccountPage from '../../features/auth/AccountPage';
import { useSelector } from 'react-redux';
import LoadingComponent from './LoadingComponent';
import ProfilePage from '../../features/profiles/profilePage/ProfilePage';
import PrivateRoute from './PrivateRoute';
import LessonDashboard from '../../features/lessons/LessonDashboard';
import Lesson from '../../features/lessons/Lesson';
import TrialLessonDashboard from '../../features/lessons/trial/TrialLessonDashboard';
import TrialLesson from '../../features/lessons/trial/TrialLesson';
import Footer from '../../features/nav/Footer';
import Terms from '../../features/nav/Terms';
import PrivacyPolicy from '../../features/nav/PrivacyPolicy';
import CheckoutForm from '../common/checkout/CheckoutForm';

export default function App() {
  const { key } = useLocation();
  const { initialized } = useSelector((state) => state.async);

  if (!initialized) return <LoadingComponent content='Loading app...' />

  return (
    <>
      <ModalManager />
      <ToastContainer position='bottom-right' hideProgressBar />
      <Route exact path='/' component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <>
            <NavBar />
            <Container className='main'>
              <Route exact path='/events' component={EventDashboard} />
              <Route exact path='/triallessons' component={TrialLessonDashboard} />
              <Route exact path='/terms' component={Terms} />
              <Route exact path='/privacy' component={PrivacyPolicy} />
              {/*<Route exact path='/checkout' component={CheckoutForm} />*/}
              <Route exact path='/lessons' component={LessonDashboard} />
              {/*<Route exact path='/sandbox' component={Sandbox} />
              <Route path='/events/:id' component={EventDetailedPage} />*/}
              <Route path='/lessons/:id' component={Lesson} />
              <Route path='/triallessons/:id' component={TrialLesson} />
              <PrivateRoute
                path={['/createEvent', '/manage/:id']}
                component={EventForm}
                key={key}
              />
              <PrivateRoute path='/account' component={AccountPage} />
              <PrivateRoute path='/profile/:id' component={ProfilePage} />
              <Route path='/error' component={ErrorComponent} />
            </Container>
            <Footer />
          </>
        )}
      />
    </>
  );
}
