import React from "react";
import { Message, Radio } from "semantic-ui-react";

import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { useState } from "react";

export default function HintModal({ selectedLesson }) {
  const [hideAnswer, setHideAnswer] = useState(true);

  const answerArray = selectedLesson.answerQuery.split("\n");

  //console.log(typeof selectedLesson);
  return (
    <ModalWrapper size='large' header=''>
      <Message>
        <Message.Header>Hint</Message.Header>
        <Message.List>
          {selectedLesson.hint.map((hint, i) => (
            <Message.Item key={i}>{hint}</Message.Item>
          ))}
        </Message.List>
      </Message>

      <Radio
        toggle
        label='show answer'
        onChange={() => setHideAnswer(!hideAnswer)}
        checked={!hideAnswer}
      />

      <Message hidden={hideAnswer}>
        { answerArray && answerArray.map((line, i) => 
        <Message.Content key={i}>{line}</Message.Content>
        )}
      </Message>
    </ModalWrapper>
  );
}
