import { SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";
import { APP_LOADED } from "../../app/async/asyncReducer";
import {
  getUserProfile,
  dataFromSnapshot,
  updateUserProfile,
  updateUserProfileEmailVerified,
} from "../../app/firestore/firestoreService";
import { listenToCurrentUserProfile } from "../profiles/profileActions";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { app } from "../../app/config/firebase";
import { onSnapshot } from "@firebase/firestore";

const auth = getAuth(app);

export function signInUser(user) {
  return {
    type: SIGN_IN_USER,
    payload: user,
  };
}

export function verifyEmailVerification() {
  return function (dispatch) {
    return onIdTokenChanged(auth, (user) => {
      //console.log("on id token change called")
      if (user && user.emailVerified === true) {
        //console.log("on id token change user.emailVerified = true")
        updateUserProfileEmailVerified();
      }
    }
    )
  }
}

export function verifyAuth() {
  return function (dispatch) {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then(function (idToken) {
          //console.log("authAction idToken: " + idToken)
          dispatch(signInUser({ ...user, idToken }));
          //return idToken;
        });
        // dispatch(signInUser(user));
        const profileRef = getUserProfile(user.uid);
        if (user.emailVerified === true) {
          updateUserProfileEmailVerified();
        }
        onSnapshot(profileRef, (snapshot) => {
          dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
          dispatch({ type: APP_LOADED });
        });
      } else {
        dispatch(signOutUser());
        dispatch({ type: APP_LOADED });
      }
    });
  };
}

export function signOutUser() {
  return {
    type: SIGN_OUT_USER,
  };
}
